<script lang="ts" setup>
import { MediaImageFragment } from '~/api/graphql/generated'
import { Employee } from '../../components/EmployeeSingle.vue'

const props = defineProps<{
  person: Employee
  hideImages?: boolean
}>()

const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

const email = ref(props.person.link?.url)
if (email.value && email.value.startsWith('mailto:')) {
  email.value = email.value.replace('mailto:', '')
}
const isEmail = email.value ? validateEmail(email.value) : false
const controller = useModal()
</script>

<template>
  <PersonBase :hide-image="hideImages" :person="person">
    <template v-if="isEmail">
      <button v-if="email" class="link-default text-small" @click="controller.open">{{ person.link?.text }}</button>
      <ModalContactForm v-if="email" :controller="controller">
        <EmailContactForm :email-to="email" />
      </ModalContactForm>
    </template>
    <NuxtLink v-else-if="email" :to="email">
      {{ person.link?.text }}
    </NuxtLink>
  </PersonBase>
</template>
